<template>
    <span class="MInputBox">
        <div :class="invoiceStyle ? 'MInputContent invoice' : 'MInputContent'">
            <p>
                <label style="text-align: left" :class="{'colorBlack':labelBlack}" :for="'MInput'+tag">
                    {{title}}
                    <span v-if="must">*</span>
                </label>
            </p>
            <p :class="invoiceStyle ? 'MInput invoice' : 'MInput'">
                <input :id="'MInput'+tag"
                        :maxlength="max"
                        :type="type||'text'"
                        :disabled="disabled"
                        @input="changeModel"
                        v-model="val"
                        onkeyup="type == 'tel' ? value=value.replace(/[^\d\.]/g,'') : value=value"
                        onblur="type == 'tel' ? value=value.replace(/[^\d\.]/g,'') : value=value"
                        v-if="type!='int' && type!='textarea' && type!='number' && type!='number2'"
                        :placeholder="pla">
                <input :id="'MInput'+tag"
                        :maxlength="max"
                        v-if="type=='number2'"
                        type="number"
                        :disabled="disabled"
                        @input="changeModel"
                        v-model="val"
                        :placeholder="pla">
                <van-field v-if="type=='int' || type=='number'" v-model="val" @input="changeModel" type="number" :placeholder="pla"></van-field>
                <textarea v-if="type == 'textarea'" :placeholder="pla" v-model="val" @input="changeModel" name="Text1" cols="30" rows="4"></textarea>
            </p>
        </div>
    </span>
</template>

<script>
    export default {
        name: "MInput",
        props: [
            'invoiceStyle',
            'title', //标题
            'pla', //默认文字
            'value',//绑定字段
            'disabled',//是否只读
            'max',//最大长度
            'type',//文本框类型
            'must',//是否有必填符号
            'labelBlack',//label是否黑色
            'borderBottom',//是否下边框
        ],
        data() {
            return {
                val: '',
                tag: '',
                show: false
            }
        },
        watch: {
            'value': function () {
                this.val = this.setInputValue;
            }
        },
        computed: {
            setInputValue() {
                return this.value;
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        created() {
            let math = Math.random().toString().replace('0.', '');
            let date = new Date().getTime();
            this.tag = math + date;
            this.val = this.setInputValue
        },
        methods: {
            stopKeyborad () {

                this.$refs.scanTextbox.setAttribute('readonly', 'readonly');
                setTimeout(() => {
                    this.$refs.scanTextbox.removeAttribute('readonly');
                }, 200);
            },
            showBoard(){
                this.show = true
            },
            changeModel() {
                this.$emit('change', this.val);
            }
        }
    }
</script>

<style lang="scss">
.MInputBox{
    input::-webkit-input-placeholder {
        font-size: .15rem;
        -webkit-text-fill-font-size: .15rem;
    }
    .colorBlack{
        color: #13161B !important;
        font-size: .15rem;
    }
    .MInputContent {
        padding: 0 .25rem;
    }
    .MInputContent.invoice {
        padding: 0;
    }

    .MInputContent p {
        text-align: left !important;
        font-size: .145rem;
        color: #90939A;
    }
    .MInputContent.invoice p label {
        font-size: .15rem !important;
    }

    .MInputContent span{
        color: #d60012;
    }

    .MInput {
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: .1rem;
    }
    .MInput.invoice {
        margin-bottom: 0;
    }
    .MInput textarea{
        width: calc(100% - .24rem);
        text-align: left;
        background: #E6E7E9;
        padding: .12rem;
    }
    .MInput input {
        font-size: .15rem;
        height: .35rem;
        line-height: .35rem;
        text-align: left;
        width: 100%;
        outline: none;
        color: #13161B;
        background: #fff;
    }
    .black{
        .MInput input:disabled{
            color: #909399!important;
        }
    }
    .MInput input:disabled{
        pointer-events: none
    }
    .MInput.invoice input {
        font-size: .15rem;
        height: .3rem;
        line-height: .3rem;
        text-align: left;
        width: 100%;
        outline: none;
        color: #13161B;
        background: #fff;
    }
    .van-field__control{
        font-size: .15rem!important;
        height: .35rem!important;
        line-height: .35rem!important;
        text-align: left!important;
        width: 100%!important;
        outline: none!important;
        color: #13161B!important;
        background: #fff!important;

    }
    
    .van-field__control::-webkit-input-placeholder {
        font-weight: 400;
        color: red;
    }
    .van-cell{
        padding: 0;
    }
    /deep/.van-field__body input::-webkit-input-placeholder{
        color: #909399!important;
        font-size: .15rem!important;
    }
    /deep/.van-field__body input{
        font-size: .15rem!important;
        height: .35rem!important;
        line-height: .35rem!important;
        outline: none!important;
        color: #13161B!important;
    }

}
</style>